"use client";
import { useState } from "react";
import { Button } from "./ui/button";
import axios from "axios";

type Props = {
  isPro: boolean;
};

const SubscriptionButton = ({ isPro }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleSubscription = async () => {
    try {
      setIsLoading(true);
      setError(null);
      const response = await axios.get("/api/stripe");
      window.location.href = response.data.url;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        const errorMessage =
          err.response?.data?.error ||
          "Er is een fout opgetreden bij het verwerken van uw abonnement. Probeer het later opnieuw.";
        setError(errorMessage);
        console.error("Error in handleSubscription:", err.response?.data);
      } else {
        setError(
          "Er is een onverwachte fout opgetreden. Probeer het later opnieuw."
        );
        console.error("Error in handleSubscription:", err);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-col gap-2">
      <Button
        disabled={isLoading}
        onClick={handleSubscription}
        variant={error ? "destructive" : "default"}
      >
        {isLoading
          ? "Laden..."
          : isPro
          ? "Beheer mijn Abonnement"
          : "Abonneren voor Pro "}
      </Button>
      {error && <p className="text-sm text-red-500">{error}</p>}
    </div>
  );
};

export default SubscriptionButton;
