import AWS from "aws-sdk";

export async function uploadToS3(file: File) {
  try {
        AWS.config.update({
            accessKeyId: process.env.NEXT_PUBLIC_AWS_ACCESS_KEY_ID!,
            secretAccessKey: process.env.NEXT_PUBLIC_AWS_SECRET_ACCESS_KEY!,
        });

        const file_key = `uploads/${Date.now().toString()}_${file.name.replace(/\s/g, '-')}`;

        const params = {
          Bucket: process.env.NEXT_PUBLIC_AWS_BUCKET_NAME!,
          Key: file_key,
          Body: file,
        };

        const s3 = new AWS.S3({
            params,
            region: process.env.NEXT_PUBLIC_AWS_S3_REGION!,
        });

        // Upload the file to S3 using the putObject method of the S3 client object
        await new Promise((resolve, reject) => {
          s3.putObject(params)
            .on('httpUploadProgress', (progress) => {
              const percentage = ((progress.loaded * 100) / progress.total).toFixed(2);
              console.log(`Upload progress: ${percentage}%`);
            })
            .send((err) => {
              if (err) {
                reject(err);
              } else {
                console.log(`Upload success to S3: ${file_key}`);
                resolve(null);
            }
            });
        });

    return {
            file_key,
            file_name: file.name,
    };
  } catch (error) {
    console.error('Error uploading to S3:', error);
    throw error;
}
}

export function getS3URL(file_key: string) {
    return `https://${process.env.NEXT_PUBLIC_AWS_BUCKET_NAME}.s3.${process.env.NEXT_PUBLIC_AWS_S3_REGION}.amazonaws.com/${file_key}`
}

/**
 *  uses the send() method to upload the file to S3, which is more efficient than using the promise() method and await upload.then()
 * 
 * 
 */