"use client";

import React, { useState, useEffect } from "react";
import { cn } from "@/lib/utils";
import { useDropzone } from "react-dropzone";
import { Inbox, Loader2 } from "lucide-react";
import { uploadToS3 } from "@/lib/s3";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import toast from "react-hot-toast";
import { useRouter } from "next/navigation";

export const edge = true; // Enable edge functions

const FileUpload = () => {
  const { mutate, isPending } = useMutation({
    mutationFn: async ({
      file_key,
      file_name,
    }: {
      file_key: string;
      file_name: string;
    }) => {
      const response = await axios.post("/api/create-chat", {
        file_key,
        file_name,
      });
      return response.data;
    },
  });

  const [hydrated, setHydrated] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const router = useRouter();

  useEffect(() => {
    setHydrated(true);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    accept: { "application/pdf": [".pdf"] },
    maxFiles: 1,
    onDrop: async (acceptedFiles) => {
      console.log(acceptedFiles);
      const file = acceptedFiles[0];

      if (file.size > 1024 * 1024 * 10) {
        // file size is larger than 10Mb
        toast.error("File size is larger than 10Mb");
        return;
      }

      try {
        setIsUploading(true);
        const data = await uploadToS3(file);

        if (!data) {
          toast.error("Error uploading to S3");
          return;
        }
        const { file_key, file_name } = data;

        await mutate(
          { file_key, file_name },
          {
            onSuccess: ({ chat_id }) => {
              toast.success(`Chat created successfully! Chat ID: ${chat_id}`);
              router.push(`/chat/${chat_id}`);
            },
            onError: (err) => {
              console.log(err);
              toast.error("Error creating chat");
            },
          }
        );
      } catch (error) {
        console.log(error);
      } finally {
        setIsUploading(false);
      }
    },
  });

  if (!hydrated) return null;

  return (
    <div className="p-2 bg-white rounded-xl shadow-md">
      <div
        {...getRootProps({
          className:
            "cursor-pointer border-dashed border-2 rounded-xl p-4 bg-gray-50 py-8 justify-center flex flex-col items-center",
        })}
      >
        <input {...getInputProps()} />
        {(isUploading || isPending) && (
          <div className="flex flex-col items-center justify-center">
            <Loader className="text-blue-500 w-10 h-10" />
            <p className="ml-2 text-sm text-slate-400">Uploading...</p>
          </div>
        )}
        {!isUploading && !isPending && (
          <>
            <Inbox className="w-10 h-10 text-blue-500" />
            <p className="mt-2 text-sm text-slate-400">Drop your PDF here</p>
          </>
        )}
      </div>
    </div>
  );
};

export default FileUpload;

export const Loader = ({ className }: { className?: string }) => {
  return (
    <Loader2 className={cn("my-10 text-primary/60 animate-spin", className)} />
  );
};
